import tools from "../../../extends/tools";
export default {
    dataInit: null,
    goodsList: [],
    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    saveData: {
        type: '',
        title: '',
        warehouse_id: '',
        situation: 1,
        user_id: tools.me(),
        business_date: tools.now(),
        additional_amount: 0,
        additional_amount_copy: 0,
        settlement_method: '',
        remarks: '',
        payment_amount: 0,
        payment_amount_copy: 0,
        enclosure: [],
        goods: [],
        status: 0,
        number: '',
        discount_rate: '', //折扣率
        discount: '',
        discount_copy: 0,
        customer_id: '',
        discount_copy: 0,
        isBorrow: false,
        lendId: 0
    }
}